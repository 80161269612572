<template>
    <div>
      <v-container fluid class="mt-3">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card class="rounded-l green lighten-4" outlined height="198px">
              <v-card-title class="">
                <div class="d-flex">
                  <v-icon color="green">
                    mdi-bucket
                  </v-icon>
                  <div class="ml-3">
                    Follow Up
                  </div>
                </div>
              </v-card-title>
              <v-card-text  class="p-3">
                <v-container class="py-auto fill-height">
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="12" md="8">
                      <h2 style="font-weight:600" class="pa-0 ma-0 green--text text-center">
                        {{ total_follow_up }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col> 
          <v-col cols="12" sm="12" md="6">
            <v-card class="rounded-l red lighten-4" outlined height="198px">
              <v-card-title class="">
                <div class="d-flex">
                  <v-icon color="red" >
                    mdi-chart-bubble
                  </v-icon>
                  <div class="ml-3">
                    Belum di Follow UP
                  </div>
                </div>
              </v-card-title>
              <v-card-text class="p-3">
                <v-container class="py-auto fill-height">
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="12" md="8">
                      <h2 style="font-weight:600" class="pa-0 ma-0 red--text text-center">
                        {{ total_not_follow_up }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-card class="mt-6 rounded-xxl pb-4 elevation-5" height="auto" color="#fff">
        <v-card-text>
          <v-data-table
            dense
            :headers="headers"
            :items="itemLists"
            class="elevation-1 mb-5"
            :items-per-page="30"
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50, -1]
            }"
            :loading="$store.state.overlay"
            height="440"
            fixed-header
            :divider="true"
            :light="true"
            :search="searchItem" 
            :item-class="tr_datatable"
        >    
            <template v-slot:top>
                <v-toolbar flat color="white" class="mt-2 mb-2">
                    <div class="d-flex w-100">
                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3 " v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3 hidden-sm-and-down"
                        @click="getPullData()"
                        >
                            <v-icon>mdi-cached</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3"
                        @click="dialogfilter = true"
                        >
                            <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#005c37"
                        class="py-5 mr-3 text-white hidden-sm-and-down"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                            <download-excel
                                class="text"
                                :fetch           = "exportExcel"
                                :fields="headersColumn"
                                :before-generate = "startDownload"
                                :before-finish   = "finishDownload">
                                Export Excel
                            </download-excel>
                        </v-btn>
                    </div>
                </v-toolbar>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ new Date(item.created_at).toISOString().substr(0, 10) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-switch
                  true-value="1"
                  false-value="0"
                  inset
                  dense
                  v-model="item.status"
                  @change="updateStatus(item)"
                  class="mt-1 mb-2"
                  hide-details="true"
              ></v-switch>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-list dense>
                  <v-list-item @click="detailItem(item)">
                      <v-list-item-icon>
                          <v-icon>mdi-eye</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                          <v-list-item-title>Lihat Detail</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon>
                          <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                          <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item> -->
              </v-list>
            </template>
            
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-dialog
          v-model="dialogfilter"
          persistent
          max-width="600px"
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card class="rounded-l">
          <v-card-title class="text-center mb-0 pb-0">
              <v-row>
                  <v-col cols="12">
                      <div class="d-flex">
                          <div class="ml-5">
                            Filter
                          </div>
                          <v-spacer></v-spacer>
                          <v-btn
                              class="mx-2"
                              fab
                              x-small
                              color="#e83e8c;"
                              @click="[dialogfilter = false]"
                          >
                              <v-icon>
                                  mdi-close
                              </v-icon>
                          </v-btn>
                      </div>
                  </v-col>
                  <v-divider></v-divider>
              </v-row>
          </v-card-title>
          <v-card-text class="ma-0">
            <v-container fluid class="ma-0">
              <v-row>
                <v-col class="col-12" sm="6" md="6">
                  <h6 class="blue-lcd mb-1">Start Date </h6>
                  <v-menu
                      ref="dateStartModal"
                      v-model="dateStartModal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                      >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                          solo
                          dense
                          v-model="dateStartModel"
                          label="Start Date"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          class="ma-0 pa-0 border-12"
                          hide-details=true
                          clearable
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="dateStartModel"
                          no-title
                          @input="[dateStartModal = false]"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="col-12" sm="6" md="6">
                  <h6 class="blue-lcd mb-1">End Date </h6>
                  <v-menu
                      ref="dateEndModal"
                      v-model="dateEndModal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                      >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                          solo
                          dense
                          v-model="dateEndModel"
                          label="Start Date"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          class="ma-0 pa-0 border-12"
                          hide-details=true
                          clearable
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="dateEndModel"
                          no-title
                          @input="[dateEndModal = false]"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="text-center">
            <v-row justify="center">
                <v-col cols="12" xs="12" sm="3" md="3">
                    <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogfilter = false, clear()]">Cancel</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="3" md="3">
                    <v-btn block class="rounded-l text-white" color="#20bf6b" @click="[getPullData(), dialogfilter = false]">Filter</v-btn>
                </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="dialogInfo"
          persistent
          max-width="800px"
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card class="rounded-l">
            <v-card-title class="text-center mb-0 pb-0">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex">
                            <div class="ml-5">
                              Detail Message 
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mx-2"
                                fab
                                x-small
                                color="#e83e8c;"
                                @click="[dialogInfo = false]"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-0">
              <v-container fluid class="ma-0">
                <v-row>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Fullname </h6>
                    <v-text-field
                      v-model="name"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Email </h6>
                    <v-text-field
                      v-model="email"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Phone </h6>
                    <v-text-field
                      v-model="phone"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="12" md="12">
                    <h6 class="blue-lcd mb-1">Message </h6>
                    <v-textarea solo class="ma-0 pa-0 border-12" v-model="remark"
                        hide-details=true counter label="Remark" dense></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {message_api} from "@/backend-api/message_api"

export default {
    data: () => ({
        headers: [
            { text: 'FULLNAME', value: 'name', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'EMAIL', value: 'email', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'PHONE', value: 'phone', align: 'left', class: 'primary--text blue lighten-5' },   
            { text: 'MESSAGE', value: 'message', align: 'left', class: 'primary--text blue lighten-5' },      
            { text: 'CREATED AT', value: 'created_at', align: 'left', class: 'primary--text blue lighten-5' },      
            { text: 'STATUS', value: 'status', align: 'left', class: 'primary--text blue lighten-5' },
            { text: '', value: 'actions', align: 'left', class: 'primary--text blue lighten-5' },
        ],
        dialogInfo: false,
        headersColumn:{
        'FULLNAME': 'name',
        'EMAIL': 'email',
        'PHONE': 'phone',
        'PESAN': 'message',
        'CREATED DATE': 'created_at',
        'STATUS': 'status',
        },
        itemLists: [],
        searchItem: '',
        dateStartModal: false,
        dateStartModel: '',
        dateEndModal: false,
        dateEndModel: '',
        dialogfilter : false,
        name: '',
        email: '',
        phone: '',
        remark: '',
    }),
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
          this.dateStartModel = ''
          this.dateEndModel = ''
        },
        async getPullData(){
          this.$store.dispatch('setOverlay', true)
          var respData = await message_api.index(`?start_date=${this.dateStartModel ? this.dateStartModel : ''}&end_date=${this.dateEndModel ? this.dateEndModel : ''}`, null, false, false, false)
          if (respData.status === 200) {
            this.itemLists = respData.data.data
            this.$store.dispatch('setOverlay', false)
          } else{
            this.$store.dispatch('setOverlay', false)
          }
        },
        async exportExcel(){
          var respData = await message_api.index(`?start_date=${this.dateStartModel ? this.dateStartModel : ''}&end_date=${this.dateEndModel ? this.dateEndModel : ''}`, null, false, false, false)
          if (respData.status === 200) {
            return respData.data.data
          }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        async updateStatus(item){
          var reqBody = {
            'id': item.id,
            'status': item.status
          }
          const respData = await message_api.update_status('', reqBody, false, false, false)
          if (respData.status === 200) {
            this.getPullData()
          }
        },
        detailItem(item){
          console.log(item);
          this.name = item.name
          this.email = item.email
          this.phone = item.phone
          this.remark = item.message
          this.dialogInfo = true
        }
    },
    async mounted() { 
        this.$store.dispatch('setOverlay', true)
        await this.getPullData()
        this.$store.dispatch('setOverlay', false)
    },
    watch: {
    },
    computed:{
      total_follow_up(){
          var total_follow_up = 0
          for (let index = 0; index < this.itemLists.length; index++) {
              if (this.itemLists[index].status == 1) {
                  total_follow_up = total_follow_up + 1;
              }
          }
          
          return (total_follow_up | 0)
      },
      total_not_follow_up(){
          var total_not_follow_up = 0
          for (let index = 0; index < this.itemLists.length; index++) {
              if (this.itemLists[index].status == 0) {
                  total_not_follow_up = total_not_follow_up + 1;
              }
          }
          
          return (total_not_follow_up | 0)
      },
    }
}
</script>

<style scoped>
.tr_datatable{
background-color: #F5F7F8 !important;
}

</style>